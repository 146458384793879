import { useGetOrgSettings } from '@marlin/account-data-access-organization';
import { MarlinTheme } from '@marlin/shared/theme';
import { PERIOD_ENUM } from '@marlin/shared/ui/chart';
import { periodMap } from '@marlin/shared/ui/chart';
import { displayVolumePriceUtil } from '@marlin/shared/utils-format-reading';
import NorthEastRoundedIcon from '@mui/icons-material/NorthEastRounded';
import SouthEastRoundedIcon from '@mui/icons-material/SouthEastRounded';
import { Skeleton } from '@mui/material';
import isNil from 'lodash/isNil';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  description: {
    maxWidth: theme.typography.pxToRem(184),
    fontSize: theme.typography.pxToRem(14),
    flex: 1,
  },
  trend: {
    fontWeight: theme.typography.fontWeightBold,
  },
  icon: {
    height: theme.typography.pxToRem(16),
    fontSize: theme.typography.pxToRem(16),
    position: 'relative',
    top: theme.typography.pxToRem(2),
    marginRight: '0.5ch',
  },
}));

interface ITrendDescriptionProps {
  trend?: number;
  unit?: '%' | '$';
  period: PERIOD_ENUM;
  previousValue?: number;
  currentValue?: number;
}

export const TrendDescription = ({
  trend,
  period,
  unit = '%',
  previousValue,
  currentValue,
}: ITrendDescriptionProps) => {
  const { classes } = useStyles();
  const { data } = useGetOrgSettings();

  const waterPrice = useMemo(() => {
    return data?.settings.waterPrice;
  }, [data]);

  if (isNil(trend)) {
    return (
      <div className={classes.description}>
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="60%" />
      </div>
    );
  }

  if (previousValue === 0) {
    return (
      <div className={classes.description}>
        {content.TREND_DESCRIPTION_NO_PREVIOUS_USE_1}
        <span className={classes.trend}>
          <NorthEastRoundedIcon className={classes.icon} />
          <span>{content.TREND_NO_PREVIOUS_USE_GAL(currentValue ?? 0)}</span>
        </span>
        {content.TREND_DESCRIPTION_NO_PREVIOUS_USE_2(periodMap[period].curr.toLowerCase(), periodMap[period].prev)}
      </div>
    );
  }

  if (currentValue === 0) {
    return (
      <div className={classes.description}>
        <span className={classes.trend}>
          <span>{content.TREND_NO_CURRENT_USE}</span>
        </span>
        {content.TREND_DESCRIPTION_NO_CURRENT_USE(
          previousValue ?? 0,
          periodMap[period].curr.toLowerCase(),
          periodMap[period].prev
        )}
      </div>
    );
  }

  const trendInteger = parseInt(trend.toString());
  if (trendInteger === 0) {
    return (
      <div className={classes.description}>
        {content.TREND_DESCRIPTION_1_EQUAL}
        <span className={classes.trend}>{content.TREND_EQUAL}</span>
        {content.TREND_DESCRIPTION_2_EQUAL(periodMap[period].curr.toLowerCase(), periodMap[period].prev)}
      </div>
    );
  }

  const Icon = trendInteger > 0 ? NorthEastRoundedIcon : SouthEastRoundedIcon;
  let text = '';

  if (unit === '%') {
    text = trendInteger > 0 ? content.TREND_MORE_PERCENT(trendInteger) : content.TREND_LESS_PERCENT(trendInteger);
  }

  if (unit === '$' && waterPrice?.price && waterPrice?.uoM && currentValue && previousValue) {
    const displayPrice = displayVolumePriceUtil(currentValue - previousValue, waterPrice.price, waterPrice.uoM);
    text = trendInteger > 0 ? content.TREND_MORE_DOLLAR(displayPrice) : content.TREND_LESS_DOLLAR(displayPrice);
  }

  return (
    <div className={classes.description}>
      {content.TREND_DESCRIPTION_1}
      <span className={classes.trend}>
        <Icon className={classes.icon} />
        <span>{text}</span>
      </span>
      {content.TREND_DESCRIPTION_2(periodMap[period].curr.toLowerCase(), periodMap[period].prev)}
    </div>
  );
};
