import { TVolumeDataPoint } from '@marlin/asset/data-access/home-dashboard';
import { useChartingColors } from '@marlin/asset/ui/charts';
import { createDateString, formatDate } from '@marlin/shared/utils-common-date';
import { PlotData } from 'plotly.js';
import { useCallback, useMemo } from 'react';

import { content } from '../../content';

interface IUseTotalWaterConsumptionSeriesProps {
  volumeTotal: TVolumeDataPoint[];
  previousPeriodVolumeTotal: TVolumeDataPoint[];
}

export const useTotalWaterChartSeries = ({
  volumeTotal,
  previousPeriodVolumeTotal,
}: IUseTotalWaterConsumptionSeriesProps): Partial<PlotData>[] => {
  const [currentSeriesColor, previousSeriesColor] = useChartingColors();

  const mapDataToSeries = useCallback(
    (data: TVolumeDataPoint[], name: string, color: string, xaxis?: string): Partial<PlotData> => {
      return {
        x: data.map((point) => formatDate(createDateString(new Date(point.eventDateTime)))) || [],
        y: data.map((point) => point.volume) || [],
        type: 'scatter',
        mode: 'lines',
        name,
        xaxis,
        line: { color },
      };
    },
    []
  );

  const volumeTotalSeries: Partial<PlotData> = useMemo(
    () => mapDataToSeries(volumeTotal, content.TOTAL_WATER_CONSUMPTION_CURRENT_SERIES, currentSeriesColor),
    [mapDataToSeries, volumeTotal, currentSeriesColor]
  );

  const previousPeriodVolumeTotalSeries: Partial<PlotData> = useMemo(
    () =>
      mapDataToSeries(
        previousPeriodVolumeTotal,
        content.TOTAL_WATER_CONSUMPTION_PREVIOUS_SERIES,
        previousSeriesColor,
        'x2'
      ),
    [mapDataToSeries, previousPeriodVolumeTotal, previousSeriesColor]
  );

  return useMemo(
    () => [volumeTotalSeries, previousPeriodVolumeTotalSeries],
    [previousPeriodVolumeTotalSeries, volumeTotalSeries]
  );
};
