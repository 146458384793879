import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{
  isFullScreen?: boolean;
}>()((theme: MarlinTheme, { isFullScreen }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    borderWidth: theme.typography.pxToRem(1),
    borderColor: theme.palette.divider,
    borderRadius: theme.typography.pxToRem(4),
    borderStyle: 'solid',
    backgroundColor: theme.palette.background.primary,
    flexWrap: 'wrap',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.typography.pxToRem(16),
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.typography.pxToRem(8),
  },
  headerTitle: {
    fontSize: theme.typography.pxToRem(isFullScreen ? 20 : 14),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: theme.typography.pxToRem(0.15),
    margin: 0,
  },

  logo: {
    '& > svg': {
      height: theme.typography.pxToRem(30),
    },
  },
  children: {
    flex: '1 1 auto',
  },
}));
