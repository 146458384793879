import { useDesktopSideNavbarCollapse } from '@marlin/shared/utils-common-desktop-sidenav';
import { Menu } from '@mui/material';
import { useEffect } from 'react';

import { IEntity } from '../../utils/types';
import { OrganizationDetailsContent } from './organization-details-content';
import { useStyles } from './organization-details.styles';

interface IOrganizationDetailsProps {
  entities: IEntity[];
  open: boolean;
  anchor: null | HTMLElement;
  entityName?: string;
  onClose: () => void;
  onChange: (event: React.MouseEvent<HTMLElement>) => void;
  variant: 'marlin' | 'portfolio' | 'support';
}

export const OrganizationDetails = ({
  open,
  anchor,
  entityName,
  entities,
  onClose,
  onChange,
  variant,
}: IOrganizationDetailsProps) => {
  const { classes } = useStyles();
  const { desktopNavbarCollapsed } = useDesktopSideNavbarCollapse();

  useEffect(() => {
    // close org switcher on navbar collapse when user moves out of the viewport
    if (open && desktopNavbarCollapsed) {
      onClose();
    }
  }, [desktopNavbarCollapsed, onClose, open]);

  return (
    <Menu
      anchorEl={anchor}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      className={classes.container}
    >
      <OrganizationDetailsContent
        entities={entities}
        entityName={entityName}
        onClose={onClose}
        onChange={onChange}
        variant={variant}
      />
    </Menu>
  );
};
