export const transformBigNumber = (value: number): string => {
  if (value > 1000000) {
    return value.toLocaleString('fullwide', {
      useGrouping: true,
      maximumFractionDigits: 3,
      notation: 'compact',
      compactDisplay: 'long',
    });
  }
  return value.toLocaleString('fullwide', {
    useGrouping: true,
    maximumFractionDigits: 0,
  });
};
