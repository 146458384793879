import {
  TransformDeviceUnitOfMeasuresToApi,
  UnitOfMeasure,
  UnitOfMeasureType,
} from '@marlin/shared/utils-format-reading';
import { z } from 'zod';

const PowerBiSchema = z.object({
  reportUrl: z
    .string()
    .nullish()
    .transform((uri) => {
      return uri ? encodeURIComponent(uri) : uri;
    })
    .default(null),
});

const MonnitSchema = z.object({
  apiKeyHeader: z.string(),
  apiKeyValue: z.string(),
  isActive: z.boolean(),
});

export const waterPriceSchema = z
  .object({
    price: z.number(),
    uoM: UnitOfMeasure,
  })
  .nullish()
  .default(null);

export const waterPriceParamsSchema = z
  .object({
    price: z.number(),
    uoM: TransformDeviceUnitOfMeasuresToApi,
  })
  .nullish()
  .default(null);

export const flowMeterUpdateSchema = z.array(z.string()).nullish().default(null);

export const flowMeterUpdateRequestSchema = flowMeterUpdateSchema.transform((data) => {
  return data || null;
});

export type TFlowMeterUpdateSchema = z.infer<typeof flowMeterUpdateSchema>;
export type TFlowMeterUpdateRequestSchema = z.infer<typeof flowMeterUpdateRequestSchema>;

export const OrganizationSettingsSchema = z.object({
  id: z.string(),
  organizationId: z.string(),
  settings: z.object({
    powerBI: PowerBiSchema,
    monnit: MonnitSchema,
  }),
});

export const GetOrganizationSettingsSchema = z.object({
  id: z.string(),
  organizationId: z.string(),
  settings: z.object({
    powerBI: PowerBiSchema,
    monnit: MonnitSchema,
    waterPrice: z.object({
      price: z.number(),
      uoM: UnitOfMeasureType,
    }),
  }),
});

export const UpdateOrganizationSettingsSchema = z
  .object({
    settings: PowerBiSchema,
    waterPrice: waterPriceSchema,
    defaultFlowMeters: flowMeterUpdateSchema,
    monnitSettings: MonnitSchema,
  })
  .partial();

export const UpdateOrganizationSettingsParamsSchema = UpdateOrganizationSettingsSchema.transform((data) => {
  return {
    settings: data.settings || null,
    waterPrice: data.waterPrice || null,
    defaultFlowMeters: data.defaultFlowMeters || null,
    monnitSettings: data.monnitSettings || null,
  };
});

export type TOrganizationSettings = z.infer<typeof OrganizationSettingsSchema>;
export type TGetOrganizationSettings = z.infer<typeof GetOrganizationSettingsSchema>;
export type TUpdateOrganizationSettings = z.infer<typeof UpdateOrganizationSettingsSchema>;
