export const content = {
  PERIOD: 'Period',
  ABBREVIATIONS: {
    DAY: '24H',
    WEEK: '7D',
    MONTH: '30D',
    QUARTER: '3M',
    YEAR: '12M',
    CUSTOM: 'Custom',
  },
  LONG: {
    DAY: '24 Hours',
    WEEK: '7 Days',
    MONTH: '30 Days',
    QUARTER: '3 Months',
    YEAR: '12 Months',
    CUSTOM: 'Custom',
  },
  SHORT: {
    DAY: '24',
    WEEK: '7',
    MONTH: '30',
    QUARTER: '3',
    YEAR: '12',
    CUSTOM: 'Custom',
  },
  SELECTED_PERIOD: 'selected period',
  PREVIOUS_PERIOD: 'previous period',
  COMPARE_TO: {
    PREVIOUS_PERIOD: 'Previous Period',
    MONTH_OVER_MONTH: 'Month over Month',
    QUARTER_OVER_QUARTER: 'Quarter over Quarter',
    YEAR_OVER_YEAR: 'Year over Year',
    CUSTOM_START_DATE: 'Custom Start Date',
  },
  CUSTOM_START_DATE: {
    TITLE: 'Custom Start Date',
    BACK_BUTTON: 'Back',
    CANCEL_BUTTON: 'Cancel',
    SET_BUTTON: 'Set',
    DATE_LABEL: 'Starting',
    ORGANIZATION_TIME_ZONE: (timezone: string) => `Org Timezone: ${timezone}`,
    OUTDATED_DATE_MESSAGE: (months: number) => `Date must be within the last ${months} months`,
  },
};
