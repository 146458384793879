import { useChartingColors } from '@marlin/asset/ui/charts';
import { MarlinTheme } from '@marlin/shared/theme';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';
import sum from 'lodash/sum';
import { PlotData } from 'plotly.js';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { Dot } from '../../shared/dot.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    justifyContent: 'center',
    display: 'flex',
    gap: theme.typography.pxToRem(8),
    marginTop: theme.typography.pxToRem(8),
  },
}));

interface ITotalWaterChartComponent {
  uoM?: TUnitOfMeasure | null;
  series: Partial<PlotData>[];
}
export const TotalWaterChartLegend = ({ uoM, series }: ITotalWaterChartComponent) => {
  const { classes } = useStyles();
  const colors = useChartingColors();

  const dots = useMemo(() => {
    return series.map((data, index) => {
      const total = sum(data.y);

      return {
        total,
        name: data.name,
      };
    });
  }, [series]);

  return (
    <div className={classes.wrapper}>
      {dots.map(({ total, name }, index) => {
        return (
          <Dot color={colors[index % colors.length]} key={index}>
            {`${name} `}
            <b>{content.TOTAL_AMOUNT(total, uoM || '')}</b>
          </Dot>
        );
      })}
    </div>
  );
};
