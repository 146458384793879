import { MarlinTheme } from '@marlin/shared/theme';
import { IChartSeries, IChartThresholdSeries, TChartDisplayType } from '@marlin/shared/utils-chart';
import { formatTimestamp } from '@marlin/shared/utils-common-date';
import { alpha } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

interface INoDataContent {
  from?: string | number;
  to?: string | number;
  chartData: Array<IChartSeries | IChartThresholdSeries> | ApexOptions['series'];
  chartDisplayType?: TChartDisplayType;
}

export const customFormat = 'MM/DD/YY HH:mm A z';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loader: {
    backgroundColor: alpha(theme.palette.background.primary, 0.5),
  },
  noData: {
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: theme.palette.background.primary,
    padding: theme.typography.pxToRem(12),
    borderWidth: theme.typography.pxToRem(1),
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderRadius: theme.typography.pxToRem(4),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
  },
  noDataInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.typography.pxToRem(4),
  },
  dot: {
    width: theme.typography.pxToRem(8),
    height: theme.typography.pxToRem(8),
    borderRadius: '50%',
    backgroundColor: alpha(theme.palette.error.main, 0.12),
    marginRight: theme.typography.pxToRem(4),
  },
  range: {
    fontSize: theme.typography.pxToRem(10),
  },
}));

export const isEmptyChartData = ({ chartData, from, to, chartDisplayType }: INoDataContent) => {
  if (chartDisplayType === 'bar' || chartDisplayType === 'rangeBar') {
    return !chartData?.length;
  }

  return (
    !chartData?.length ||
    chartData?.every((item) => {
      if (typeof item === 'number') return item === null;
      // TODO: check if this logic can be simplified after removing margins for bar chart

      // We cannot filter the data, because for the bar chart we need all the data.
      // Because there may be data in the margin that we need to display on the chart, we had to make IF separations for the bar chart.
      // With the specific data, for the bar chart we need to check the current element if it is 1, and the next element if it is 0 and is in the range without margins.
      if (item.type === 'rangeBar') {
        // This handle a case where there is only one value since the registration to not show misleading empty data message
        if (item.data.length === 1) {
          return false;
        }

        return item.data.every((value, index, data) => {
          if (
            value &&
            typeof value === 'object' &&
            'y' in value &&
            from &&
            typeof from === 'number' &&
            to &&
            value.y === 1
          ) {
            if (typeof to === 'number' && value.x > to) return true;

            const valueNextItem = data[index + 1];

            if (!valueNextItem || (typeof valueNextItem === 'object' && 'x' in valueNextItem && valueNextItem.x > from))
              return false;
          }

          return true;
        });
      }

      return item.data.every((value) => {
        if (!value || (typeof from !== 'number' && typeof to !== 'number')) return true;

        if (typeof value === 'number') return value === null;

        return Array.isArray(value)
          ? value.every((v) => v === null)
          : value && value.x && from && to
          ? (typeof from === 'number' && value.x < from) || (typeof to === 'number' && value.x > to) || value.y === null
          : value.y === null;
      });
    })
  );
};

export const EmptyChartData = ({ chartData, from, to, chartDisplayType }: INoDataContent) => {
  const { classes } = useStyles();

  const isEmpty = isEmptyChartData({ chartData, from, to, chartDisplayType });

  return isEmpty ? (
    <div className={classes.noData}>
      <div className={classes.noDataInfo}>
        <div className={classes.dot} />
        {content.NO_DATA_ON_MAINCHART}
      </div>
      <div className={classes.range}>
        {content.NO_DATA_ON_MAINCHART_RANGE(formatTimestamp(from, customFormat), formatTimestamp(to, customFormat))}
      </div>
    </div>
  ) : null;
};
