import { aercoEquipmentModeLabelMap } from './mode-mappers-aerco.config';
import {
  intellistationEquipmentModeLabelMap,
  tekmarSbsEquipmentModeLabelMap,
} from './mode-mappers-intellistation.config';
import { sentinelEquipmentModeLabelMap } from './mode-mappers-sentinel.config';
import { TModeMap, TStatusMap } from './types';

export const statusMode: { [key in TModeMap]: TStatusMap } = {
  intellistation: intellistationEquipmentModeLabelMap,
  tekmarSbs: tekmarSbsEquipmentModeLabelMap,
  aerco: aercoEquipmentModeLabelMap,
  sentinel: sentinelEquipmentModeLabelMap,
};
