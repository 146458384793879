import { AVERAGING_FUNCTION_FILTER, TChartDisplayType } from '@marlin/shared/utils-chart';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';
import { EQUIPMENT_TAB_VALUES } from '@marlin/shared/utils-routes';
import { TDatapoint, TDatapointWithMetadata } from '@marlin/shared/utils/datapoint-mappers';
import { TDevice, TEquipment } from '@marlin/shared/utils/zod';
import { ReactElement } from 'react';

export const chartIdList = [
  'temperature',
  'pressure',
  'flow',
  'pumpvalve',
  'other',
  'flamestrength',
  'pumpstatus',
] as const;
export type TChartId = (typeof chartIdList)[number];

export interface IConfig {
  headerBanner: ReactElement | null;
  tabs: {
    [key in EQUIPMENT_TAB_VALUES]?: ReactElement;
  };
  dashboard: IDashboardConfig;
  details: TEquipmentDetailsRow[];
}

type TEquipmentDashboardSectionMetadataItem = {
  name: string;
  label: string;
  eventCodes?: string[];
};

export type TEquipmentDashboardSectionMetadata = {
  title: string;
  sectionName: string;
  items: Array<TEquipmentDashboardSectionMetadataItem>;
  icon?: ReactElement;
};

export interface IDashboardSectionStyle {
  fullWidth?: boolean;
  rowMaxLength?: number;
}

export interface ISection {
  title: string;
  sectionDatapoints?: TEquipmentDashboardSectionMetadata[];
  dataTestId: string;
  style?: IDashboardSectionStyle;
}

export interface IChartConfigOptions {
  averagingFunctionFilter?: AVERAGING_FUNCTION_FILTER;
}

export interface IDashboardConfig {
  productDetails:
    | {
        datapoints: { name: string; label: string; statusMapping?: Map<string, string>; eventCodes?: string[] }[];
        imageSrc?: ((type: string) => string) | string;
        logoSrc?: string;
        shouldDisplayRole: boolean;
        shouldDisplaySerialNumber: boolean;
      }
    | undefined;
  topAlerts: {
    show: boolean;
  };
  commonSectionSettings?: Partial<ISection>;
  sections: ISection[];
  liveDataExpirationSeconds: number;
  enableStartStopData: boolean;
  chart?: {
    datapointNames: string[];
    datapointGroupNames: string[];
    title: string;
    subTitle?: string;
    isAdvancedButton: boolean;
    calculateValues: boolean;
    chartType?: TChartDisplayType;
    invertBarDatapointNames?: string[];
    options?: IChartConfigOptions;
  };
  isLastReadingTimeCounter: boolean;
  dynamic?: boolean;
  datapointCallback?: (datapoint: TDatapointWithMetadata, allDatapoints: TDatapoint[]) => TDatapointWithMetadata;
}

export type TEquipmentValueSource = 'equipment' | 'datapoints' | 'mix' | 'device';

type TEquipmentDetailsRowCommon = {
  label: string;
  tooltip?: string;
  valueCallback?: (value: unknown) => string | undefined;
};

type TEquipmentDetailsDatapointSource = TEquipmentDetailsRowCommon & {
  valueSource: 'datapoints';
  name: string | string[];
};

type TEquipmentDetailsEquipmentSource = TEquipmentDetailsRowCommon & {
  valueSource: 'equipment';
  name: keyof TEquipment | Array<keyof TEquipment>;
};

type TEquipmentDetailsMixSource = TEquipmentDetailsRowCommon & {
  valueSource: 'mix';
  name: keyof TEquipment | Array<keyof TEquipment> | string[];
};

type TEquipmentDetailsDeviceSource = TEquipmentDetailsRowCommon & {
  valueSource: 'device';
  name: keyof TDevice | Array<keyof TDevice>;
};

type TEquipmentDetailsAdditionalPropertiesSource = TEquipmentDetailsRowCommon & {
  valueSource: 'additionalProperties';
  name: string;
};

export type TEquipmentDetailsRow =
  | TEquipmentDetailsDatapointSource
  | TEquipmentDetailsEquipmentSource
  | TEquipmentDetailsMixSource
  | TEquipmentDetailsDeviceSource
  | TEquipmentDetailsAdditionalPropertiesSource;

export interface IGlobalEquipmentConfig {
  dashboard: {
    liveDataExpirationSeconds: number;
  };
  chart: IChart[];
}

export interface IChart {
  name: TChartId;
  label: string;
  type: TChartDisplayType;
  hideUomOnAxis?: boolean;
  additionalAxisUom?: TUnitOfMeasure;
  mainDatapoint?: string;
}
