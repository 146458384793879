import { MarlinTheme } from '@marlin/shared/theme';
import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  dot: {
    height: theme.typography.pxToRem(12),
    width: theme.typography.pxToRem(12),
    borderRadius: '50%',
    marginTop: theme.typography.pxToRem(2),
    marginRight: theme.typography.pxToRem(4),
    display: 'inline-block',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
  name: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    width: 'calc(100% - 12px)',
    whiteSpace: 'wrap',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
  },
}));

interface IDotProps extends PropsWithChildren {
  color: string;
}

export const Dot = ({ color, children }: IDotProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.dot} style={{ backgroundColor: color }} />
      <span className={classes.name}>{children}</span>
    </div>
  );
};
