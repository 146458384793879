import { MarlinTheme } from '@marlin/shared/theme';
import { useTheme } from '@mui/material';

import { content } from './content';
import { ErrorPageLayout } from './page-layout.component';

interface INotFoundPage {
  onBackClick: () => void;
  onlyContent?: boolean;
}

export const NotFoundPage = (props: INotFoundPage) => {
  const theme = useTheme<MarlinTheme>();

  return <ErrorPageLayout content={content.NOT_FOUND} {...props} errorIcon={theme.icon.notFoundLogo} />;
};
