import { z } from 'zod';

import { EquipmentTypeEnum } from '../data-access-shared-types/equipment.model';

export const EquipmentFormTypes = z.enum([
  'intellistation',
  'tekmarSmartBoiler',
  'aercoBenchmark',
  'aercoInnovation',
  'sentinel',
  'other',
] as const);

export const UpsertEquipmentForm = z
  .object({
    commissionDate: z.string().optional(),
    description: z.string().optional(),
    locationId: z.string().optional(),
    locationName: z.string().optional(),
    brand: z.string().optional(),
    model: z.string().optional(),
    type: EquipmentTypeEnum.optional(),
    typeObject: z.object({ id: EquipmentTypeEnum, name: z.string() }).optional(),
    serialNumber: z.string().optional(),
    name: z.string(),
    formType: EquipmentFormTypes.optional(),
    registrationCode: z.string().optional(),
  })
  .transform((data) => ({
    ...data,
    location: { id: data.locationId, name: data.locationName },
  }));

export type TUpsertEquipmentForm = z.infer<typeof UpsertEquipmentForm>;
