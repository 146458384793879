import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { TEquipment } from '@marlin/shared/utils/zod';
import { useContext } from 'react';

import { DeleteEquipmentModalBody } from './delete-modal/delete-equipment-modal-body.component';
import { DeleteEquipmentModalFooter } from './delete-modal/delete-equipment-modal-footer.component';
import { DeleteEquipmentModalTitle } from './delete-modal/delete-equipment-modal-title.component';
import { DeregisterEquipmentModalBody } from './deregister-modal/deregister-equipment-modal-body.component';
import { DeregisterEquipmentModalFooter } from './deregister-modal/deregister-equipment-modal-footer.component';
import { DeregisterEquipmentModalTitle } from './deregister-modal/deregister-equipment-modal-title.component';

export const useRemoveEquipment = () => {
  const { modalDispatch } = useContext(ModalContext);

  const modalDeregisterPayload = (equipment?: TEquipment) => ({
    title: <DeregisterEquipmentModalTitle />,
    body: (
      <DeregisterEquipmentModalBody
        deleteAssetName={equipment?.name}
        assetId={equipment?.devices?.[0]?.deviceId || equipment?.devices?.[0]?.id || equipment?.id}
        type={equipment?.type}
      />
    ),
    footer: <DeregisterEquipmentModalFooter deviceCount={equipment?.deviceCount || 0} assetId={equipment?.id} />,
  });
  const modalDeletePayload = (equipment?: TEquipment) => ({
    title: <DeleteEquipmentModalTitle deviceCount={equipment?.deviceCount || 0} />,
    body: (
      <DeleteEquipmentModalBody
        deleteAssetName={equipment?.name}
        deviceCount={equipment?.deviceCount || 0}
        assetId={equipment?.id}
      />
    ),
    footer: <DeleteEquipmentModalFooter deviceCount={equipment?.deviceCount || 0} assetId={equipment?.id} />,
  });

  const removeEquipment = (equipment?: TEquipment) => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.SHOW,
      payload: equipment?.isRegistered ? modalDeregisterPayload(equipment) : modalDeletePayload(equipment),
    });
  };

  return { removeEquipment };
};
