import { MarlinTheme } from '@marlin/shared/theme';
import { alpha, toggleButtonGroupClasses } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  text: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: '400',
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: theme.typography.pxToRem(1),
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  toggle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  toggleItem: {
    display: 'flex',
    alignItems: 'center',
  },
  toggleButton: {
    paddingLeft: theme.typography.pxToRem(12),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.04),
    },
  },
  buttonText: {
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
  },
  selectedItemText: {
    textTransform: 'none',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(14),
    fontStyle: 'normal',
    fontWeight: '500',
    color: theme.palette.primary.main,
    letterSpacing: theme.typography.pxToRem(1),
    lineHeight: theme.typography.pxToRem(24),
  },
  toggleGroupSelected: {
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      width: theme.typography.pxToRem(112),
    },
  },
  toggleGroup: {
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      border: 0,
      width: theme.typography.pxToRem(112),
      borderRadius: theme.shape.borderRadius,
      [`&.${toggleButtonGroupClasses.disabled}`]: {
        border: 0,
      },
    },
  },
}));
