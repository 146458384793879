export const content = {
  FIRST_NAME_CONTROL_LABEL: 'First Name',
  FIRST_NAME_CONTROL_VALIDATION: 'Please enter valid first name',

  LAST_NAME_CONTROL_LABEL: 'Last Name',
  LAST_NAME_CONTROL_VALIDATION: 'Please enter valid last name',

  EMAIL_CONTROL_LABEL: 'Email Address',
  EMAIL_CONTROL_VALIDATION: 'Please enter valid e-mail address',

  JOB_TITLE_CONTROL_LABEL: 'Title',

  PHONE_NUMBER_CONTROL_LABEL: 'Phone Number',
  PHONE_NUMBER_CONTROL_VALIDATION: 'Please enter valid phone number',

  ROLE_CONTROL_LABEL: 'Role',
  ROLE_CONTROL_VALIDATION: 'Please select user role',

  ROLE_ADMIN_LABEL: 'Admin',
  ROLE_ADMIN_HELPER_TEXT: 'Able to add/edit information, automations, and users (best for contributing)',
  ROLE_VIEWER_LABEL: 'Viewer',
  ROLE_VIEWER_HELPER_TEXT:
    "Able to view your organization's platform with no editing abilities (best for being informed)",
  ROLE_SUPPORT_LABEL: 'Nexa Support',
  ROLE_SUPPORT_HELPER_TEXT:
    'Edit access to all organizations, portfolios, and features. This access should be limited to as few people as possible.',
  ROLE_CSM_LABEL: 'Nexa Customer Success Manager',
  ROLE_CSM_HELPER_TEXT:
    'Nexa Customer Success team members show may service the specific organizations they are assigned to. Customer data is read-only',
  ROLE_BUCS_LABEL: 'Business Unit Customer Service',
  ROLE_BUCS_HELPER_TEXT:
    'Watts Business Unit Customer Service team members have read only access to all organizations with registered equipment in Nexa.',

  SENSOR_TYPE_LABEL: 'Sensor Type',
  SENSOR_TYPE_TEMPERATURE_LABEL: 'Temperature Sensor',
  SENSOR_TYPE_PRESSURE_LABEL: 'Pressure Sensor',
  SENSOR_TYPE_LEAK_LABEL: 'Leak Detector',
  SENSOR_TYPE_FLOW_LABEL: 'Flow Meter',

  TIME_UNIT_VALUE: 'Duration',
  TIME_UNIT_LABEL: 'Time Unit',
  TIME_UNITS_DAYS: 'days',
  TIME_UNITS_HOURS: 'hours',
  TIME_UNITS_MINUTES: 'minutes',

  LOADING_LABEL: 'Loading...',

  ORGANIZATION_NAME_LABEL: 'Organization Name',
  ORGANIZATION_ROLE_LABEL: 'Role',
  SELECTED_ORG_COUNT_LABEL: (count: number) => `Selected Organizations ${count}`,
  VIEWER_CELL_TOOLTIP: 'Creating portfolio with Viewer Role is not supported right now.',
  TABLE_NO_DATA: 'No results found.',
  TABLE_ERROR: 'Something went wrong. Please try again later.',

  CLEAR_ALL: 'Clear All',

  PORTFOLIO_ROLE_MANAGER_LABEL: 'Portfolio Manager',
  PORTFOLIO_ROLE_MANAGER_HELPER_TEXT: 'Able to add/edit information, automations, and users (best for contributing)',
  PORTFOLIO_ROLE_VIEWER_LABEL: 'Portfolio Viewer',
  PORTFOLIO_ROLE_VIEWER_HELPER_TEXT:
    'Able to view your portfolio’s data with no editing abilities (best for being informed)',

  ALL_ORGANIZATIONS: 'All Organizations',
  SET_ROLE_INDIVIDUALLY: 'Set user role for all organizations individually',
  USER_ALREADY_EXISTS: 'User already exists in this org',

  POSTAL_CODE: 'Postal Code',
  ZIP_CODE: 'ZIP Code',
  ONLY_NUMBER: 'Only numbers may be entered',
  REQUIRED_CHARACTERS: (value: number) => `Value must have ${value} characters`,
  INVALID_POSTAL_CODE: 'Postal code is invalid for chosen country',

  STATE_LABEL: 'State',
  CANADIANS_STATE_LABEL: 'Province/Territory',

  FLOW_LITERS: 'L/min - Liters per minute',
  FLOW_GALLONS: 'gpm - Gallons per minute',
  FLOW_CUBIC_METER: 'm³/h - Cubic meter per hour',

  TEMPERATURE_CELCIUS: '°C - Celsius',
  TEMPERATURE_FAHRENHEIT: '°F - Fahrenheit',

  GAS_FEET: 'SCF - Standard cubic feet',
  GAS_METERS: 'Sm³ - standard cubic meters',

  PRESSURE_POUNDS: 'psi - Pounds per square inch',
  PRESSURE_KILOPASCAL: 'kPa - Kilopascal',

  ENERGY_MBTU: 'MBTU - Thousand British thermal units',
  ENERGY_KBTU: 'kBTU - Thousand British thermal units',
  ENERGY_MBH: 'MBH - Thousand British thermal units per hour',
  ENERGY_GIGAJOULE: 'GJ - Gigajoule',
  ENERGY_KWH: 'kWh - Kilowatt-hour',
  ENERGY_THERM: 'thm - Therm',

  TEMPERATURE_CONTROL_LABEL: 'Temperature',
  FLOW_CONTROL_LABEL: 'Flow',
  PRESSURE_CONTROL_LABEL: 'Pressure',
  // ENERGY_CONTROL_LABEL: 'Energy & Power',
  ENERGY_CONTROL_LABEL: 'Energy',
  GAS_CONTROL_LABEL: 'Gas',

  EDIT_LABEL: (label: string) => `Edit ${label}`,

  ADDRESS_ERROR: 'Address does not appear to follow a valid format. Please check.',
  MISSING_ADDRESS_ERROR: (addressComponents: string[]) =>
    addressComponents.includes('invalid_format')
      ? 'Address does not appear to follow the validated format. Please check.'
      : `At least one address element is unresolved, unexpected, or missing: ${addressComponents.join(', ')}`,

  TIME_ZONE_LABEL: 'Time Zone',
  TIME_ZONE_HELPER_TEXT: 'Time zones defined by ',
  TIME_ZONE_HELPER_TEXT_LINK: 'IANA standards',
  MAX_LENGTH: (max: number) => `Value cannot exceed ${max} characters`,

  MECHANICAL_ROOM: 'Mechanical Room',
  GUEST_ROOM: 'Guest Room',
  LAUNDRY: 'Laundry',
  KITCHEN: 'Kitchen',
  NOT_CLASSIFIED: ' ',
  LOCATION_TYPE_LABEL: 'Location Type',

  INTELLISTATION_TYPE: 'Powers IntelliStation 2',
  TEKMAN_SMART_BOILER_TYPE: 'Tekmar Smart Boiler Controller 294',
  AERCO_BENCHMARK_TYPE: 'AERCO Benchmark Boiler',
  AERCO_INNOVATION_TYPE: 'AERCO Innovation Water Heater',
  SENTINEL_TYPE: 'Sentinel Leak Defense',
  OTHER_TYPE: 'Other',
  OTHER_DESCRIPTION: 'Select "Other" if your equipment is not compatible with Nexa.',
  CHOOSE_EQUIPMENT_LABEL: 'Choose Equipment',
  ADDRESS_FORM: {
    ADD_ADDRESS: 'Add address',
    NEW_LOCATION_ADDRESS: 'New Location Address',
    CANCEL: 'Cancel',
    SAVE: 'Save',
    COUNTRY_LABEL: 'Country',
    ADDRESS_1_LABEL: new Map([
      ['Mexico', 'Street Address'],
      ['Canada', 'Address 1'],
      ['United States', 'Address 1'],
      ['', 'Address 1'],
    ]),
    ADDRESS_2_LABEL: new Map([
      ['Mexico', 'Apt / Suite / Other'],
      ['Canada', 'Address 2'],
      ['United States', 'Address 2'],
      ['', 'Address 2'],
    ]),
    CITY_LABEL: 'City',
    REQUIRED: 'This is a required field',
    MAX_LENGTH: (max: number) => `Value cannot exceed ${max} characters`,
  },
};
