import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Popover, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { MouseEvent, useCallback, useState } from 'react';

import { useStyles } from './unit-selector.styles';
import { IUnitOption } from './use-unit-selector';

interface IUnitSelector {
  selectedOption: IUnitOption;
  unitOptions: IUnitOption[];
  handleChange: (event: MouseEvent<HTMLElement>, option: IUnitOption) => void;
  title?: string;
}

export const UnitSelector = ({ unitOptions, selectedOption, handleChange, title }: IUnitSelector) => {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onChange = useCallback(
    (event: MouseEvent<HTMLElement>, option: IUnitOption) => {
      handleChange(event, option);
      handleClose();
    },
    [handleChange, handleClose]
  );

  const open = Boolean(anchorEl);

  return (
    <>
      <Typography className={classes.text}>{title}</Typography>
      <ToggleButtonGroup
        className={classes.toggleGroupSelected}
        value="modal"
        exclusive
        onChange={handleClick}
        color="primary"
      >
        <ToggleButton className={classes.toggleButton} value={selectedOption} size="small">
          <div className={classes.toggle}>
            <Typography className={classes.selectedItemText}>{selectedOption.label}</Typography>
          </div>
          {open ? <ExpandLessIcon color="primary" /> : <ExpandMoreIcon color="primary" />}
        </ToggleButton>
      </ToggleButtonGroup>
      <Popover
        open={open}
        onClose={handleClose}
        anchorPosition={{
          top: anchorEl?.getBoundingClientRect().bottom ?? 0,
          left: anchorEl?.getBoundingClientRect().left ?? 0,
        }}
        anchorReference="anchorPosition"
      >
        <ToggleButtonGroup
          className={classes.toggleGroup}
          orientation="vertical"
          value="modal"
          exclusive
          onChange={onChange}
          color="primary"
        >
          {unitOptions.map((option) => (
            <ToggleButton className={classes.toggleButton} value={option} size="small">
              <div className={classes.toggleItem}>
                <Typography className={classes.buttonText}>{option.label}</Typography>
              </div>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Popover>
    </>
  );
};
