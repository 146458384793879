import { MouseEvent, useCallback, useState } from 'react';

export interface IUnitOption {
  value: string;
  label: string;
}

interface IUseUnitSelector {
  unitOptions: IUnitOption[];
}

export const useUnitSelector = ({ unitOptions }: IUseUnitSelector) => {
  const [selectedOption, setSelectedOption] = useState<IUnitOption>(unitOptions[0]);

  const handleChange = useCallback((_: MouseEvent<HTMLElement>, option: IUnitOption) => {
    setSelectedOption(option);
  }, []);

  return {
    selectedOption,
    handleChange,
  };
};
