import { TSubtypesParams } from '../infrastructure/schema/subtypes.schema';
import { TWaterTrendsParams } from '../infrastructure/schema/water-trends.schema';
import { IFilterParams } from './filter-params.utils';

enum QUERY_KEY {
  DEVICES = 'devices',
  DEVICE = 'device',
  DEVICE_DETAILS = 'device_details',
  PIN_DEVICES = 'pin-devices',
  HOME_LOCATIONS = 'home-locations',
  MANUFACTURER = 'manufacturer',
  SUBTYPES = 'subtypes',
  CALIBRATIONS = 'calibrations',
  WATER_TRENDS = 'water-trends',
}

export const queryKey = {
  DEVICES: (filterParams?: IFilterParams) => (filterParams ? [QUERY_KEY.DEVICES, filterParams] : [QUERY_KEY.DEVICES]),
  DEVICE: (id: string) => [QUERY_KEY.DEVICE, id],
  DEVICE_DETAILS: (id: string) => [QUERY_KEY.DEVICE_DETAILS, id],
  PIN_DEVICES: () => [QUERY_KEY.PIN_DEVICES],
  HOME_LOCATIONS: () => [QUERY_KEY.HOME_LOCATIONS],
  MANUFACTURER: (id: string) => [QUERY_KEY.MANUFACTURER, id],
  DEVICE_SUBTYPES: (subtypesParams: TSubtypesParams) => [QUERY_KEY.SUBTYPES, subtypesParams],
  WATER_TRENDS: (params: TWaterTrendsParams) => [QUERY_KEY.WATER_TRENDS, params],
  CALIBRATIONS: (filterParams?: IFilterParams) =>
    filterParams ? [QUERY_KEY.CALIBRATIONS, filterParams] : [QUERY_KEY.CALIBRATIONS],
};
