import { useEquipmentDetails as useEquipmentDetailsQuery, useMetadata } from '@marlin/asset/data-access/equipment';
import { globalConfigAerco, globalConfigIntellistation, globalConfigPvi } from '@marlin/asset/shared/equipment-config';
import {
  ChartsSection,
  DurationProvider,
  Header,
  HeaderSection,
  HistoricalChartingConfigProvider,
  SelectedDatapointsProvider,
  useCalculateValues,
  useHistoricalChartingConfigContext,
  useSelectedDatapointsContext,
} from '@marlin/asset/ui/charts';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { ModalFullScreen } from '@marlin/shared/ui-modal';
import { Paper } from '@marlin/shared/ui-page';
import { ErrorPage } from '@marlin/shared/ui/chart';
import { CustomPeriodModalProvider } from '@marlin/shared/utils-chart';
import { routes } from '@marlin/shared/utils-routes';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { ModelEnum } from '@marlin/shared/utils/zod';
import { Divider, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const getEquipmentConfig = (model?: string) => {
  switch (model) {
    case ModelEnum.enum.INTELLISTATION:
      return globalConfigIntellistation;
    case ModelEnum.enum.AERCO_BOILER_LN:
    case ModelEnum.enum.AERCO_BOILER_DUAL:
    case ModelEnum.enum.AERCO_WATER_HEATER_N:
    case ModelEnum.enum.AERCO_WATER_HEATER_INNOVATION:
      return globalConfigAerco;
    case ModelEnum.enum.PVI_BRIGADE:
      return globalConfigPvi;
    case ModelEnum.enum.CONQUEST_100_20L100A_GCL:
    case ModelEnum.enum.CONQUEST_100_25L100A_GCL:
    case ModelEnum.enum.CONQUEST_100_30L100A_GCL:
    case ModelEnum.enum.CONQUEST_1100_CQT1100:
    case ModelEnum.enum.CONQUEST_1100_CQTO1100:
    case ModelEnum.enum.CONQUEST_1200_CQT1200:
    case ModelEnum.enum.CONQUEST_1200_CQTO1200:
    case ModelEnum.enum.CONQUEST_130_100L130A_GCML:
    case ModelEnum.enum.CONQUEST_130_40L130A_GCML:
    case ModelEnum.enum.CONQUEST_130_40L130A_GCMLW:
    case ModelEnum.enum.CONQUEST_130_50L130A_GCML:
    case ModelEnum.enum.CONQUEST_130_50L130A_GCMLW:
    case ModelEnum.enum.CONQUEST_130_60L130A_GCML:
    case ModelEnum.enum.CONQUEST_130_60L130A_GCMLW:
    case ModelEnum.enum.CONQUEST_130_70L130A_GCMLW:
    case ModelEnum.enum.CONQUEST_130_70L130A_GCML:
    case ModelEnum.enum.CONQUEST_130_80L130A_GCML:
    case ModelEnum.enum.CONQUEST_130_80L130A_GCMLW:
    case ModelEnum.enum.CONQUEST_130_90L130A_GCML:
      return globalConfigPvi;
    default:
      return null;
  }
};

export const HistoricalChart = () => {
  const equipmentId = useIdFromPathname();
  const { data, isError, isLoading, refetch } = useEquipmentDetailsQuery({
    equipmentId,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    data: metadata,
    isLoading: isMetadataLoading,
    refetch: refetchMetadata,
    isError: isMetadataError,
  } = useMetadata({ equipmentId, enabled: false });
  const config = getEquipmentConfig(data?.model);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const toggleFullScreen = () => {
    setIsFullScreen((prevState) => !prevState);
  };

  const onRefresh = useCallback(() => {
    refetch();
    refetchMetadata();
  }, [refetch, refetchMetadata]);

  if (isMobile || (!config && !isLoading)) {
    return <Navigate to={routes.equipments.details.url(equipmentId)} replace />;
  }

  if (isError || isMetadataError || !config) {
    return <ErrorPage onRefresh={onRefresh} inTab />;
  }

  if (isMetadataLoading) {
    return <LoadingSpinner />;
  }

  return (
    <SelectedDatapointsProvider chartDatapointsMetadata={metadata.chartDatapoints}>
      <HistoricalChartingConfigProvider charts={config.chart}>
        <DurationProvider>
          {isFullScreen ? (
            <ModalFullScreen isFullScreen={isFullScreen}>
              <HistoricalChartInternal
                data={data}
                isLoading={isLoading}
                toggleFullScreen={toggleFullScreen}
                isFullScreen={isFullScreen}
              />
            </ModalFullScreen>
          ) : (
            <HistoricalChartInternal
              data={data}
              isLoading={isLoading}
              toggleFullScreen={toggleFullScreen}
              isFullScreen={isFullScreen}
            />
          )}
        </DurationProvider>
      </HistoricalChartingConfigProvider>
    </SelectedDatapointsProvider>
  );
};

interface IHistoricalChartInternalProps {
  data?: ReturnType<typeof useEquipmentDetailsQuery>['data'];
  isLoading: boolean;
  toggleFullScreen: () => void;
  isFullScreen: boolean;
}

const HistoricalChartInternal = ({
  isLoading,
  data,
  isFullScreen,
  toggleFullScreen,
}: IHistoricalChartInternalProps) => {
  const { setDefault } = useSelectedDatapointsContext();
  const { charts } = useHistoricalChartingConfigContext();
  const { chartDatapoints, isLoadingMonitorValues } = useCalculateValues(true);

  useEffect(() => {
    setDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper>
      <CustomPeriodModalProvider>
        <Header isLoading={isLoading} toggleFullScreen={toggleFullScreen} isFullScreen={isFullScreen} />
        <Divider />
        {charts.map((chartSettings) => (
          <div key={chartSettings.name}>
            <ChartsSection chartSettings={chartSettings} />
            <Divider />
          </div>
        ))}
      </CustomPeriodModalProvider>
      {chartDatapoints && data?.brand !== 'aerco' && !['aerco', 'PVI'].includes(data?.brand ?? '') && (
        <HeaderSection chartDatapoints={chartDatapoints} isLoading={isLoadingMonitorValues} />
      )}
    </Paper>
  );
};
