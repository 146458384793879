export const content = {
  REQUIRED: 'This is a required field',
  MIN_MAX: 'Must be between 3 and 200 characters',
  COMMISSION_DATE: 'Commission Date',
  DATE_VALIDATION_FUTURE: 'The date cannot be in the future, please try again',
  COMMON_MAX_LENGTH: 'Enter a maximum of 50 characters.',
  SERIAL_NUMBER_INVALID: `Enter 9 to 20 characters separated by dashes: 1 or 2 letters, 2 digits, and up to 14 or 15 digits (4 minimum), e.g., G-12-1234`,
  SERIAL_NUMBER_OR_FIXED_ID_INVALID:
    'Either the Serial Number or Controller Fixed ID is incorrect. Please check your serial number and activation code and try again.',
  FIXED_ID_INVALID: 'Enter a numerical value between 4 and 10 digits. E.g.: 1234',
  REGISTER_CODE_MIN_MAX: 'You must enter 8 digits, no spaces or special characters. E.g.: 12345678',
  REGISTER_CODE_NUMBERS_ONLY: 'You must enter 9 digits, no spaces or special characters. E.g.: 123456789',
};
