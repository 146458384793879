import { UnitOfMeasureType } from '@marlin/shared/utils-format-reading';
import { z } from 'zod';

export const waterTrendsRequestSchema = z.object({
  periodStart: z.string(),
  periodEnd: z.string(),
});

export const waterTrendsResponseSchema = z.object({
  periodStart: z.string(),
  periodEnd: z.string(),
  totalVolume: z.number(),
  previousPeriodTotalVolume: z.number(),
  trend: z.number(),
  uoM: UnitOfMeasureType.nullish(),
});

export type TWaterTrendsParams = z.infer<typeof waterTrendsRequestSchema>;
export type TWaterTrendsResponse = z.infer<typeof waterTrendsResponseSchema>;
