import type { IConfig } from '@marlin/asset/shared/equipment-config';
import { globalConfigIntellistation } from '@marlin/asset/shared/equipment-config';
import { sbcImage, tekmarLogo } from '@marlin/asset/shared/ui/assets';
import { EQUIPMENT_TAB_VALUES } from '@marlin/shared/utils-routes';

import { HistoricalChart } from '../../../components/chart/historical-chart';
import { content } from '../../../content';
import { EventLog } from '../../components/event-log/event-log.component';
import { detailsConfig } from './details-config';
import { sectionConfig } from './section-config';

export const tekmarSmartBoilerConfig: IConfig = {
  headerBanner: null,
  tabs: {
    [EQUIPMENT_TAB_VALUES.EVENT_LOG]: <EventLog />,
    [EQUIPMENT_TAB_VALUES.HISTORICAL_CHART]: <HistoricalChart />,
  },
  details: detailsConfig,
  dashboard: {
    ...globalConfigIntellistation.dashboard,
    productDetails: {
      datapoints: [
        {
          name: 'supplyTemp',
          label: content.DATAPOINT_SBS_LABELS.BOILER_SUPPLY_TEMP,
          statusMapping: new Map([['absent', content.EQUIPMENT_STATUS_LABELS.NOT_INSTALLED]]),
        },
        { name: 'boilerTarget', label: content.DATAPOINT_SBS_LABELS.TARGET },
        { name: 'boilerTotalOutput', label: content.DATAPOINT_SBS_LABELS.PLANT_OUTPUT },
      ],
      imageSrc: sbcImage,
      logoSrc: tekmarLogo,
      shouldDisplayRole: false,
      shouldDisplaySerialNumber: false,
    },
    topAlerts: {
      show: true,
    },
    enableStartStopData: true,
    sections: sectionConfig,
    chart: {
      datapointNames: ['supplyTemp', 'boilerTarget'],
      datapointGroupNames: ['temperature'],
      title: content.CHART_TITLE_MONITOR_INTELLISTATION,
      isAdvancedButton: true,
      calculateValues: true,
    },
    isLastReadingTimeCounter: false,
  },
};
