import { useGetOrganizationDetails } from '@marlin/account-data-access-organization';
import { MarlinTheme } from '@marlin/shared/theme';
import { Loadable } from '@marlin/shared/ui-loader';
import { SectionContainer } from '@marlin/shared/ui-page';
import { CompareToSelect, Period, useGetSustainabilityFormattedTimezone } from '@marlin/shared/ui/chart';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { Options } from '../shared/options/options';
import { UnitSelector } from '../shared/unit-selector/unit-selector.component';
import { useUnitSelector } from '../shared/unit-selector/use-unit-selector';
import { useFullScreen } from '../shared/use-full-screen.hook';
import { TDashboardSection } from '../types';
import { TotalWaterChart } from './components/total-water-chart.component';
import { useTotalWaterData } from './hooks/use-total-water-data.hook';
import { useTotalWaterDownload } from './hooks/use-total-water-download.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  sections: {
    padding: theme.typography.pxToRem(16),
    height: '100%',
    maxHeight: theme.typography.pxToRem(620),
  },
  filters: {
    display: 'flex',
    gap: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const unitOptions = [
  {
    value: 'volume',
    label: content.VOLUME,
  },
  {
    value: 'dollars',
    label: content.DOLLARS,
  },
];

interface ITotalWaterConsumptionProps {
  fullScreenChart: TDashboardSection | null;
  handleFullScreenClick: (section: TDashboardSection | null) => void;
}

export const TotalWaterConsumption = ({ fullScreenChart, handleFullScreenClick }: ITotalWaterConsumptionProps) => {
  const { classes } = useStyles();
  const {
    handleChangePeriod,
    selectedPeriod,
    waterTotal,
    isLoading,
    onCompareToChange,
    compareToValue,
    currentPeriod,
    handleCustomRangeSet,
  } = useTotalWaterData();

  const { handleDownloadClick } = useTotalWaterDownload(
    waterTotal?.volumeTotal || [],
    waterTotal?.previousPeriodVolumeTotal || []
  );

  const { openFullScreen, closeFullScreen, isFullScreen } = useFullScreen(
    handleFullScreenClick,
    fullScreenChart,
    'totalWaterConsumption'
  );
  const { selectedOption, handleChange } = useUnitSelector({ unitOptions });
  const { data: orgDetails } = useGetOrganizationDetails();
  const { timezone } = useGetSustainabilityFormattedTimezone(orgDetails?.timeZone);

  return (
    <SectionContainer
      title={content.TOTAL_WATER_CONSUMPTION_TITLE}
      isFullScreen={isFullScreen}
      selector={<UnitSelector selectedOption={selectedOption} handleChange={handleChange} unitOptions={unitOptions} />}
      options={
        <Options
          onDownloadClick={handleDownloadClick}
          onFullScreenOpen={openFullScreen}
          onFullScreenClose={closeFullScreen}
          isFullScreenOpen={isFullScreen}
        />
      }
    >
      <div className={classes.sections}>
        <div className={classes.filters}>
          <Period
            timezone={timezone}
            onChange={handleChangePeriod}
            handleCustomRangeSet={handleCustomRangeSet}
            selectedPeriod={selectedPeriod}
            currentPeriod={currentPeriod}
          />
          <CompareToSelect
            onChange={onCompareToChange}
            value={compareToValue}
            currentPeriodStart={currentPeriod.from?.clone().toISOString()}
            timezone={timezone}
          />
        </div>

        <Loadable showLoader={isLoading}>
          <TotalWaterChart
            isFullScreen={isFullScreen}
            uoM={waterTotal?.uoM}
            volumeTotal={waterTotal?.volumeTotal || []}
            previousPeriodVolumeTotal={waterTotal?.previousPeriodVolumeTotal || []}
            range={currentPeriod}
          />
        </Loadable>
      </div>
    </SectionContainer>
  );
};
