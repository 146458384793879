import { IConfig, globalConfigSentinel } from '@marlin/asset/shared/equipment-config';
import { sentinelImage, sentinelLogo } from '@marlin/asset/shared/ui/assets';
import { AVERAGING_FUNCTION_FILTER } from '@marlin/shared/utils-chart';

import { content } from '../../../content';
import { detailsConfig } from './details-config';

export const sentinelConfig: IConfig = {
  headerBanner: null,
  tabs: {},
  details: detailsConfig,
  dashboard: {
    ...globalConfigSentinel.dashboard,
    dynamic: true,
    productDetails: {
      datapoints: [
        {
          name: 'InLdsAlarm',
          label: '',
        },
        {
          name: 'WaterOn',
          label: '',
        },
      ],
      imageSrc: sentinelImage,
      logoSrc: sentinelLogo,
      shouldDisplayRole: false,
      shouldDisplaySerialNumber: false,
    },
    topAlerts: {
      show: false,
    },
    enableStartStopData: false,
    commonSectionSettings: {
      style: {
        fullWidth: true,
      },
    },
    sections: [],
    isLastReadingTimeCounter: false,
    chart: {
      datapointNames: ['InLdsAlarm', 'WaterOn'],
      datapointGroupNames: [],
      title: content.CHART_TITLE_SENTINEL,
      isAdvancedButton: false,
      calculateValues: false,
      chartType: 'rangeBar',
      invertBarDatapointNames: ['WaterOn'],
      options: {
        averagingFunctionFilter: AVERAGING_FUNCTION_FILTER.STATUS,
      },
    },
  },
};
