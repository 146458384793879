import { MarlinTheme } from '@marlin/shared/theme';
import { Link } from '@marlin/shared/ui-page';
import { routes } from '@marlin/shared/utils-routes';
import { createDataTestId } from '@marlin/shared/utils/storybook-utils';
import { useStore } from '@xyflow/react';
import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

import { TStatus } from '../types';
import {
  getBackgroundByEquipmentStatus,
  getBorderColorByEquipmentStatus,
  getIconColorByEquipmentStatus,
} from '../utils/utils';

interface IHeaderProps {
  status?: TStatus;
  testid?: string;
  variant?: 'default' | 'max';
  isModeEnabled?: boolean;
  equipmentId: string;
  isDetailed?: boolean;
}

interface IStylesProps {
  status?: TStatus;
  isDetailed?: boolean;
}

const useStyles = makeStyles<IStylesProps>()((theme: MarlinTheme, { status, isDetailed }) => ({
  header: {
    backgroundColor: getBackgroundByEquipmentStatus(status, theme),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(2),
    padding: theme.typography.pxToRem(8),
    borderLeft: `1px solid ${getBorderColorByEquipmentStatus(status, theme)}`,
    borderRight: `1px solid ${getBorderColorByEquipmentStatus(status, theme)}`,
    borderBottom: `1px solid ${getBorderColorByEquipmentStatus(status, theme)}`,
    svg: {
      color: getIconColorByEquipmentStatus(status, theme),
    },
    height: theme.typography.pxToRem(isDetailed ? 40 : 64),
    boxSizing: 'border-box',
  },
  defaultHeader: {
    backgroundColor: getBackgroundByEquipmentStatus(status, theme),
    display: 'flex',
    flexDirection: 'column',
    padding: theme.typography.pxToRem(8),
    border: `1px solid ${getBorderColorByEquipmentStatus(status, theme)}`,
    borderRadius: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(8)} 0 0`,
    gap: theme.typography.pxToRem(2),
    svg: {
      color: getIconColorByEquipmentStatus(status, theme),
    },
    height: theme.typography.pxToRem(isDetailed ? 40 : 64),
    boxSizing: 'border-box',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));

export const BaseHeader = ({
  status,
  testid,
  children,
  isModeEnabled,
  equipmentId,
  isDetailed,
}: PropsWithChildren<IHeaderProps>) => {
  const { classes, cx } = useStyles({ status, isDetailed });
  const draggable = useStore((store) => store.nodesDraggable);

  const headerContent = (
    <div
      className={cx(isModeEnabled ? classes.header : classes.defaultHeader)}
      data-testid={createDataTestId('equipment-header', testid)}
    >
      {children}
    </div>
  );

  if (draggable) {
    return headerContent;
  }

  return (
    <Link
      to={routes.equipmentDashboard.details.url(equipmentId)}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.link}
    >
      {headerContent}
    </Link>
  );
};
