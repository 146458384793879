import { FormHelperTextProps, TextField } from '@mui/material';
import IMask from 'imask';
import kebabCase from 'lodash/kebabCase';
import { FocusEventHandler } from 'react';
import { ControllerRenderProps, FieldError } from 'react-hook-form';

import { INPUT_TYPE } from './input-type.enum';
import { useStyles } from './input.styles';
import { IEndAdornment, IStartAdornment, TMask, useInputProps } from './use-input-props.hook';

export interface IInputProps extends Partial<ControllerRenderProps> {
  id?: string;
  label?: string;
  type?: INPUT_TYPE;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  mask?: TMask;
  tooltip?: JSX.Element;
  fullWidth?: boolean;
  error?: FieldError;
  className?: string;
  externalEndAdornment?: IEndAdornment;
  externalStartAdornment?: IStartAdornment;
  multiline?: boolean;
  maxRows?: number;
  testId?: string;
  maxLength?: number;
  helperText?: string;
  autoFocus?: boolean;
  onFocus?: FocusEventHandler<HTMLInputElement> | undefined;
  prepare?: (value: string, masked: unknown, flags: IMask.AppendFlags) => string;
  shrink?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export function Input({
  id,
  label,
  type = INPUT_TYPE.TEXT,
  required,
  disabled,
  placeholder,
  mask,
  tooltip,
  fullWidth = true,
  error,
  className,
  externalEndAdornment,
  externalStartAdornment,
  multiline,
  maxRows,
  testId,
  maxLength,
  helperText,
  onFocus,
  onBlur,
  prepare,
  shrink,
  autoFocus,
  inputRef,
  ...field
}: IInputProps) {
  const { classes } = useStyles({ tooltip: !!tooltip });

  const { inputProps } = useInputProps(type, mask, tooltip, externalEndAdornment, externalStartAdornment, prepare);

  if (type === INPUT_TYPE.HIDDEN) {
    return (
      <TextField
        name={field.name}
        id={id}
        value={field.value}
        type={INPUT_TYPE.HIDDEN}
        style={{ display: 'none' }}
        inputProps={{ 'data-testid': testId }}
      />
    );
  }

  return (
    <TextField
      className={className ? `${classes.input} ${className}` : classes.input}
      id={id}
      label={label}
      required={required}
      fullWidth={fullWidth}
      error={!!error}
      helperText={error?.message || helperText}
      disabled={disabled}
      placeholder={placeholder}
      data-testid={testId || `${kebabCase(label).toLowerCase()}`}
      type={type === INPUT_TYPE.NUMBER ? INPUT_TYPE.TEXT : type}
      InputProps={{
        ...inputProps,
        inputProps: {
          maxLength,
          'data-testid': `input-${testId || kebabCase(label).toLowerCase()}`,
          ...(inputProps?.inputProps || {}),
          onBlur,
          onFocus,
        },
      }}
      InputLabelProps={{ shrink }}
      multiline={multiline}
      maxRows={maxRows}
      FormHelperTextProps={
        {
          'data-testid': testId ? `${testId}-helper-text` : `input-${kebabCase(label).toLowerCase()}-helper-text`,
          sx: { whiteSpace: 'pre-line' },
        } as Partial<FormHelperTextProps & { 'data-testid': string }>
      }
      autoFocus={autoFocus}
      inputRef={inputRef}
      {...field}
    />
  );
}
