import { useCurrentUser } from '@marlin/account-data-access-organization';
import { useEquipmentSettingsGroup, useMetadata } from '@marlin/asset/data-access/equipment';
import { mergeSettingsDatapointsWithMetadata } from '@marlin/asset/shared/utils/datapoint-mappers';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { DeviceUserMeasuresPreferences } from '@marlin/shared/utils-format-reading';
import { useSearchParams } from '@marlin/shared/utils-router';
import { useCallback, useMemo } from 'react';

import { content } from '../../content';

interface IUseEquipmentSettings {
  equipmentId: string;
  expandedGroup: string | null;
}

export const useEquipmentSettings = ({ equipmentId, expandedGroup }: IUseEquipmentSettings) => {
  const { enqueueSnackbar } = useSnackbar();
  const [, setSearchParams] = useSearchParams();
  const { data: { settings = {} } = {} } = useCurrentUser();

  const onError = useCallback(() => {
    enqueueSnackbar(content.ERROR, { variant: 'error', preventDuplicate: true });

    setSearchParams((currentParams) => {
      if (currentParams.has('settingGroupId')) {
        currentParams.delete('settingGroupId');
      }
      return currentParams;
    });
  }, [enqueueSnackbar, setSearchParams]);

  const { data: metadataResponse, isLoading } = useMetadata({ equipmentId });
  const { data: settingsResponse, isFetching: isEquipmentSettingsGroupLoading } = useEquipmentSettingsGroup({
    equipmentId,
    settingGroupId: expandedGroup,
    onError,
  });

  const mergedSettingsDatapointsWithMetadata = useMemo(
    () =>
      mergeSettingsDatapointsWithMetadata(
        metadataResponse?.datapoints,
        settingsResponse?.datapoints,
        DeviceUserMeasuresPreferences.parse(settings)
      ),
    [metadataResponse, settingsResponse, settings]
  );

  return {
    data: mergedSettingsDatapointsWithMetadata,
    isLoading: isLoading || isEquipmentSettingsGroupLoading,
  };
};
