import { EQUIPMENT_UPSERT_ERROR, EquipmentUpsertErrorEnum } from '@marlin/asset/data-access/equipment';
import { EquipmentFormTypes } from '@marlin/asset/shared/utils/equipment-upsert';
import { TEquipmentFormTypes } from '@marlin/shared/ui-form';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { ErrorResponseWithCode, ModelEnum, TUpsertEquipmentForm } from '@marlin/shared/utils/zod';
import { UseFormReturn } from 'react-hook-form';

import { content } from './form/content';

export const getRegistrationErrorCode = (error: unknown): EQUIPMENT_UPSERT_ERROR | undefined => {
  const parsedError = ErrorResponseWithCode.safeParse(error);

  if (parsedError.success) {
    const errorCode = parsedError.data.response.data.errorCode;
    const parsedErrorCode = EquipmentUpsertErrorEnum.safeParse(errorCode);

    if (parsedErrorCode.success) {
      return parsedErrorCode.data;
    }
  }

  return undefined;
};

export const handleRegistrationError = (
  errorCode: EQUIPMENT_UPSERT_ERROR,
  setError: UseFormReturn<TUpsertEquipmentForm>['setError'],
  enqueueSnackbar: ReturnType<useSnackbar>['enqueueSnackbar'],
  equipmentFormType?: TEquipmentFormTypes
) => {
  switch (errorCode) {
    case EQUIPMENT_UPSERT_ERROR.EQUIPMENT_REGISTRATION_CODE_DOES_NOT_EXIST:
      setError('registrationCode', {
        type: 'custom',
        message: content.REGISTER_CODE_INCORRECT,
      });
      break;
    case EQUIPMENT_UPSERT_ERROR.EQUIPMENT_REGISTRATION_CODE_ALREADY_USED:
      setError('registrationCode', {
        type: 'custom',
        message: content.REGISTER_CODE_ALREADY_USED,
      });
      break;
    case EQUIPMENT_UPSERT_ERROR.EQUIPMENT_DEVICE_ALREADY_REGISTERED:
      if (equipmentFormType && ['aercoBenchmark', 'aercoInnovation'].includes(equipmentFormType)) {
        setError('registrationCode', {
          type: 'custom',
        });
        setError('serialNumber', {
          type: 'custom',
        });
        return;
      }

      enqueueSnackbar(content.EQUIPMENT_ALREADY_REGISTERED, {
        variant: 'error',
        preventDuplicate: true,
      });
      break;
    case EQUIPMENT_UPSERT_ERROR.EQUIPMENT_NAME_MUST_BE_UNIQUE:
      setError('name', {
        type: 'custom',
        message: content.EQUIPMENT_NAME_NOT_UNIQUE,
      });
      break;
    case EQUIPMENT_UPSERT_ERROR.EQUIPMENT_REGISTRATION_CODE_EXPIRED:
    case EQUIPMENT_UPSERT_ERROR.EQUIPMENT_REGISTRATION_CODE_OBSOLETE:
      setError('registrationCode', {
        type: 'custom',
        message: content.REGISTER_CODE_EXPIRED,
      });
      break;
    case EQUIPMENT_UPSERT_ERROR.EQUIPMENT_COMMISSION_DATE_CANNOT_BE_IN_FUTURE:
      setError('commissionDate', {
        type: 'custom',
        message: content.DATE_VALIDATION_FUTURE,
      });
      break;
    case EQUIPMENT_UPSERT_ERROR.INVALID_DEVICE:
    case EQUIPMENT_UPSERT_ERROR.EQUIPMENT_FIXED_ID_DOES_NOT_EXIST:
    case EQUIPMENT_UPSERT_ERROR.EQUIPMENT_FIXED_ID_DOES_NOT_MATCH:
      setError('registrationCode', {
        type: 'custom',
      });
      setError('serialNumber', {
        type: 'custom',
      });
      break;
    default:
      break;
  }
};

export const getEquipmentTypeByModel = (equipmentModel?: string) => {
  switch (equipmentModel) {
    case ModelEnum.enum.SENTINEL:
      return EquipmentFormTypes.enum.sentinel;
    case ModelEnum.enum.INTELLISTATION:
      return EquipmentFormTypes.enum.intellistation;
    case ModelEnum.enum.TEKMAR_SMART_BOILER:
      return EquipmentFormTypes.enum.tekmarSmartBoiler;
    case ModelEnum.enum.AERCO_BOILER_LN:
    case ModelEnum.enum.AERCO_BOILER_DUAL:
    case ModelEnum.enum.AERCO_WATER_HEATER_N:
    case ModelEnum.enum.AERCO_WATER_HEATER_INNOVATION:
      return EquipmentFormTypes.enum.aercoBenchmark;
    default:
      return EquipmentFormTypes.enum.other;
  }
};
