import { useFeatureFlagsContext } from '@marlin/shared/utils-common-feature-flags-context';
import { EquipmentFormTypes } from '@marlin/shared/utils/zod';
import { useMemo } from 'react';

import { content } from '../content';

export const useEquipmentType = () => {
  const { boiler } = useFeatureFlagsContext();
  const equipmentTypes = useMemo(() => {
    const options = [
      { id: EquipmentFormTypes.enum.intellistation, name: content.INTELLISTATION_TYPE },
      { id: EquipmentFormTypes.enum.tekmarSmartBoiler, name: content.TEKMAN_SMART_BOILER_TYPE },
      { id: EquipmentFormTypes.enum.aercoBenchmark, name: content.AERCO_BENCHMARK_TYPE },
      { id: EquipmentFormTypes.enum.aercoInnovation, name: content.AERCO_INNOVATION_TYPE },
      { id: EquipmentFormTypes.enum.sentinel, name: content.SENTINEL_TYPE },
      { id: EquipmentFormTypes.enum.other, name: content.OTHER_TYPE, description: content.OTHER_DESCRIPTION },
    ];

    return options.filter(
      (option) =>
        option.id !== EquipmentFormTypes.enum.aercoBenchmark ||
        (boiler && option.id === EquipmentFormTypes.enum.aercoBenchmark)
    );
  }, [boiler]);

  return { equipmentTypes };
};
