import { PropsWithChildren } from 'react';

import { LoadingSpinner } from './loading-spinner.component';

interface ILoadableProps extends PropsWithChildren {
  showLoader: boolean;
}

export const Loadable = ({ showLoader, children }: ILoadableProps) => {
  if (showLoader) {
    return <LoadingSpinner />;
  }

  return children;
};
