import { Equipment, EquipmentTypeEnum } from '@marlin/shared/utils/zod';
import { z } from 'zod';

export enum REGISTERED_EQUIPMENT_TYPE {
  POWERS_INTELLISTATION = 'PowersIntellistation',
  TEKMAR_SMART_BOILER = 'TekmarSmartBoiler',
  SENTINEL = 'Sentinel',
  AERCO_WATER_HEATER = 'AercoWaterHeater',
  AERCO_BOILER = 'AercoBoiler',
}

export enum EQUIPMENT_UPSERT_ERROR {
  EQUIPMENT_REGISTRATION_CODE_EXPIRED = 401,
  EQUIPMENT_REGISTRATION_CODE_ALREADY_USED = 402,
  EQUIPMENT_REGISTRATION_CODE_DOES_NOT_EXIST = 403,
  EQUIPMENT_REGISTRATION_CODE_OBSOLETE = 404,
  EQUIPMENT_DEVICE_ALREADY_REGISTERED = 405,
  EQUIPMENT_NAME_MUST_BE_UNIQUE = 406,
  LINKED_DEVICES = 100,
  EQUIPMENT_COMMISSION_DATE_CANNOT_BE_IN_FUTURE = 410,
  INVALID_DEVICE = 11,
  EQUIPMENT_FIXED_ID_DOES_NOT_EXIST = 421,
  EQUIPMENT_FIXED_ID_DOES_NOT_MATCH = 422,
  INVALID_LOCATION = 15,
}

export const EquipmentUpsertErrorEnum = z.nativeEnum(EQUIPMENT_UPSERT_ERROR);
export type TEquipmentUpsertError = z.infer<typeof EquipmentUpsertErrorEnum>;

export const EquipmentPayloadBase = z.object({
  name: z.string(),
  locationId: z.string(),
  description: z.string().optional(),
  commissionDate: z.string().optional(),
  serialNumber: z.string().optional(),
});
export const OtherEquipmentPayload = z
  .object({
    brand: z.string().optional(),
    model: z.string().optional(),
    type: z.string().optional(),
  })
  .merge(EquipmentPayloadBase);
export type TOtherEquipmentPayload = z.infer<typeof OtherEquipmentPayload>;

export const RegisteredEquipmentPayload = z
  .object({
    registrationCode: z.string(),
  })
  .merge(EquipmentPayloadBase)
  .transform((data) => ({ ...data, registrationFlow: REGISTERED_EQUIPMENT_TYPE.POWERS_INTELLISTATION }));
export type TRegisteredEquipmentPayload = z.infer<typeof RegisteredEquipmentPayload>;

export const SmartBoilerPayload = z
  .object({
    registrationCode: z.string(),
  })
  .merge(EquipmentPayloadBase)
  .transform((data) => ({ ...data, registrationFlow: REGISTERED_EQUIPMENT_TYPE.TEKMAR_SMART_BOILER }));
export type TSmartBoilerPayload = z.infer<typeof RegisteredEquipmentPayload>;

export const AercoWaterHeaterEquipmentPayload = RegisteredEquipmentPayload.transform((data) => ({
  ...data,
  registrationFlow: REGISTERED_EQUIPMENT_TYPE.AERCO_WATER_HEATER,
}));
export type TAercoWaterHeaterEquipmentPayload = z.infer<typeof AercoWaterHeaterEquipmentPayload>;

export const AercoBoilerEquipmentPayload = RegisteredEquipmentPayload.transform((data) => ({
  ...data,
  registrationFlow: REGISTERED_EQUIPMENT_TYPE.AERCO_BOILER,
}));
export type TAercoBoilerEquipmentPayload = z.infer<typeof AercoBoilerEquipmentPayload>;

export const SentinelEquipmentPayload = RegisteredEquipmentPayload.transform((data) => ({
  ...data,
  registrationFlow: REGISTERED_EQUIPMENT_TYPE.SENTINEL,
}));
export type TSentinelEquipmentPayload = z.infer<typeof SentinelEquipmentPayload>;

export const EquipmentsResponse = z.array(Equipment);

export const RegisteredEquipmentResponse = z.object({
  name: z.string(),
  locationId: z.string().nullable(),
  serialNumber: z.string().nullable(),
  brand: z.string().nullable(),
  model: z.string().nullable(),
  description: z.string().nullable(),
  commissionDate: z.string().nullish(),
  type: EquipmentTypeEnum.nullish(),
  isRegistered: z.boolean().optional(),
  registrationStatusChangeDate: z.string().nullish(),
  id: z.string(),
  organizationId: z.string().nullish(),
  deviceCount: z.number().nullish(),
  locationName: z.string().nullish(),
});
export type TRegisteredEquipmentResponse = z.infer<typeof RegisteredEquipmentResponse>;
