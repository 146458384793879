import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const equipmentChartSize = {
  height: {
    mobile: 200,
    desktop: 300,
  },
};

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  accordion: {
    '&.Mui-expanded': {
      margin: 0,
    },
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
  },
  accordionSummary: {
    width: '100%',
    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: theme.typography.pxToRem(24),
      },
    },
    '&.Mui-expanded:hover': {
      cursor: 'default',
    },
  },
  paper: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('md')]: {
      fontSize: 'initial',
    },
  },
  chartWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  chartButtons: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
  },
  chartDetailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    maxHeight: '100%',
  },
  divider: {
    margin: `${theme.typography.pxToRem(14)} 0`,
  },
  mainChart: {
    height: theme.typography.pxToRem(equipmentChartSize.height.desktop),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: 0,
      height: theme.typography.pxToRem(equipmentChartSize.height.mobile),
    },
  },
  chartContainer: {
    '.nsewdrag.drag.cursor-pointer': {
      cursor: 'default',
    },
  },
  chartHeaderButton: {
    position: 'absolute',
    right: 0,
    zIndex: theme.zIndex.fab,
  },
  datapointsWrapper: {
    display: 'flex',
    gap: theme.typography.pxToRem(24),
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.typography.pxToRem(16),
    },
  },
  rangeBar: {
    marginTop: 0,
    marginLeft: theme.typography.pxToRem(-24),
  },
}));
