import { SLIDE, TEASER_MODAL_ACTION_TYPE, TeaserButton, useTeaserModal } from '@marlin/account/feature/teaser';
import {
  AutomatedActionFilters,
  AutomatedActionList as UIAutomatedActionList,
  AutomationHeaderActions,
  IExtendedAutomation,
  IFilterParams,
  RULE_CATEGORY,
  content,
} from '@marlin/alert/ui/automated-action-list';
import { MarlinTheme } from '@marlin/shared/theme';
import { PageLoader } from '@marlin/shared/ui-loader';
import { IRequestPagination, PageContainer, PageHeader, Pagination } from '@marlin/shared/ui-page';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { useRouter } from '@marlin/shared/utils-router';
import { getLogger } from '@marlin/shared/utils/logger';
import MiscellaneousServicesRoundedIcon from '@mui/icons-material/MiscellaneousServicesRounded';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { AutomatedActionListTabs } from './automated-action-list-tabs.component';
import { DeleteModal } from './delete-modal.component';
import { useNavigation } from './use-navigation.hook';
import { useStore } from './use-store.hook';
import { useSensorUrlQuery } from './use-url-query.hook';

const defaultPaginationParams = { page: 1, pageSize: 10 };
const defaultFilterParams = { equipmentId: [], deviceId: [], locationId: [], term: '', isEnabled: null };

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    width: theme.static.defaultWidth,
  },
}));

interface IRouterState {
  clearFilters?: boolean;
}

export function AutomatedActionList() {
  const { classes } = useStyles();
  const { getCreatePageLink, getUpdatePageLink, getListPageLink } = useNavigation();
  const router = useRouter<IRouterState>();

  const deviceId = useSensorUrlQuery();
  const { modalDispatch } = useTeaserModal();
  const currentRouterState = useMemo(() => router.getState(), [router]);
  const initialFilterParams = useMemo(() => {
    if (!deviceId) {
      return defaultFilterParams;
    }
    return { ...defaultFilterParams, deviceId: [deviceId] };
  }, [deviceId]);
  const [params, setParams] = useState<IFilterParams>(initialFilterParams);
  const [requestPagination, setRequestPagination] = useState<IRequestPagination>(defaultPaginationParams);
  const { automations, isLoading, isListLoading, isError, updateAutomation, pagination } = useStore({
    ...params,
    ...requestPagination,
    ruleCategory: RULE_CATEGORY.PERFORMANCE,
    term: params?.term?.trim(),
  });
  const [emptyState, setEmptyState] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<IExtendedAutomation | null>(null);

  const allowDelete = usePermission(PERMISSIONS.DELETE_AUTOMATION);
  const allowEdit = usePermission(PERMISSIONS.EDIT_AUTOMATION);
  const allowCustomAutomations = usePermission(PERMISSIONS.CUSTOM_AUTOMATIONS);

  const openDeleteModal = useCallback(
    (automation: IExtendedAutomation): void => {
      setDeleteModalOpen(automation);
    },
    [setDeleteModalOpen]
  );
  const closeDeleteModal = useCallback(() => setDeleteModalOpen(null), [setDeleteModalOpen]);

  const handleParamsChange = useCallback(
    (newParams: IFilterParams) => {
      setRequestPagination({
        pageSize: requestPagination.pageSize,
        page: defaultPaginationParams.page,
      });
      setParams(newParams);
    },
    [setParams, requestPagination.pageSize]
  );

  const handleClearParams = useCallback(() => {
    // TODO: (INVESTIGATION) Find out why history push won't re-render component
    setRequestPagination(defaultPaginationParams);
    setParams(defaultFilterParams);
    router.goTo(getListPageLink());
  }, [getListPageLink, router]);

  const handlePaginationChange = useCallback((requestPagination: IRequestPagination) => {
    setRequestPagination(requestPagination);
  }, []);

  const handleAutomationEdit = useCallback(
    (automationId: string) => {
      router.goTo(getUpdatePageLink(automationId));
    },
    [getUpdatePageLink, router]
  );

  const handleAutomationCreate = useCallback(() => {
    router.goTo(getCreatePageLink());
    getLogger()?.track('CreateNewAutomationButtonClicked');
  }, [getCreatePageLink, router]);

  useEffect(() => {
    if (!isListLoading) {
      setEmptyState(automations.length === 0);
    }
  }, [setEmptyState, automations, isListLoading]);

  useEffect(() => {
    setRequestPagination({
      pageSize: defaultPaginationParams.pageSize,
      page: defaultPaginationParams.page,
    });
    setParams(initialFilterParams);
  }, [initialFilterParams]);

  useEffect(() => {
    if (currentRouterState?.clearFilters) {
      setParams(initialFilterParams);
    }
  }, [currentRouterState, initialFilterParams]);

  const handleOpenTeaserModal = () => {
    modalDispatch({
      type: TEASER_MODAL_ACTION_TYPE.SHOW_TEASER_MODAL,
      payload: {
        slide: SLIDE.AUTOMATION,
      },
    });
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <PageContainer prefix="automations-page" className={classes.container}>
      <PageHeader
        icon={<MiscellaneousServicesRoundedIcon />}
        actions={
          allowCustomAutomations ? (
            <AutomationHeaderActions onHeaderButtonClick={handleAutomationCreate} />
          ) : (
            <TeaserButton onClick={handleOpenTeaserModal} variant={'main'} text={content.HEADER_BUTTON_TITLE} />
          )
        }
        title={content.AUTOMATIONS_TITLE}
        subtitle={content.AUTOMATIONS_SUBTITLE}
        prefix="automations-header"
      />
      <AutomatedActionListTabs />
      <AutomatedActionFilters onClearParams={handleClearParams} onChangeParams={handleParamsChange} />
      <UIAutomatedActionList
        data={automations}
        onAutomationEdit={handleAutomationEdit}
        onAutomationToggle={updateAutomation}
        onAutomationDelete={openDeleteModal}
        isLoading={isListLoading}
        isError={isError}
        emptyState={emptyState}
        allowDelete={allowDelete}
        allowEdit={allowEdit}
      />
      {!emptyState && <Pagination pagination={pagination} onPaginationChange={handlePaginationChange} />}
      <DeleteModal automation={deleteModalOpen} onClose={closeDeleteModal} />
    </PageContainer>
  );
}
